import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import './index.css'

const Footer = () => {
    return (
        <div className="footer-container">
            <div className="footer">
                <div className="footer-left">
                    <div className="footer-left-top">Created by Liyi Zhang. Built with <a href="https://www.gatsbyjs.com/" target="_blank">Gatsby</a>.</div>
                    <div className="footer-left-bottom"></div>
                </div>
                <div className="footer-right">
                    <a href="mailto:gattonero1052@gmail.com">
                        <FontAwesomeIcon icon={faEnvelope} /></a>
                    <a href="https://github.com/gattonero1052/" target="_blank">
                        <FontAwesomeIcon icon={faGithub} /></a>
                </div>
            </div>
        </div>
    )
}


export default Footer;